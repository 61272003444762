import React from "react";
import ReferenceContent from "../components/Reference/ReferenceContent";

const Reference = () => {
  return (
    <div className="w-full h-full">
      <ReferenceContent />
    </div>
  );
};

export default Reference;
