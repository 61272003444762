import React from "react";
import AboutUsContent from "../components/AboutUs/AboutUsContent";

const AboutUs = () => {
  return (
    <div className="w-full h-full">
      <AboutUsContent />
    </div>
  );
};

export default AboutUs;
