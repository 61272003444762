import React from 'react'
import ContactPage from '../components/Contact/ContactPage'

const Contact = () => {
  return (
    <div className='w-full h-full'>
      <ContactPage />
    </div>
  )
}

export default Contact
