import React from "react";
import ProductDetailContent from "../components/Products/ProductDetailContent";

const ProductDetail = () => {
  return (
    <div className="w-full h-full">
      <ProductDetailContent />
    </div>
  );
};

export default ProductDetail;
