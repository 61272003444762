import React from "react";
import ProductContent from "../components/Products/ProductContent";

const Products = () => {
  return (
    <div className="w-full h-full">
      <ProductContent />
    </div>
  );
};

export default Products;
