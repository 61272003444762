import React from "react";
import CalculaterContent from "../components/Calculator/CalculatorContent";

const Calculater = () => {
  return (
    <div className="w-full h-full">
      <CalculaterContent />
    </div>
  );
};

export default Calculater;
