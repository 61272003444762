import React from "react";
import AnalysisContent from "../components/Analysis/AnalysisContent";

const Analysis = () => {
  return (
    <div className="w-full h-full">
      <AnalysisContent />
    </div>
  );
};

export default Analysis;
